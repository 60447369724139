<template>
  <LoginView v-if="routeName === 'Login'" />
  <RegisterView v-else-if="routeName === 'ResetPassword'" />
  <RegistrationUserView v-else-if="routeName === 'RegistrationUserView'" />



  <w-app v-else row class="bgApp vueApp">
    <div id="blockView" :class="{ blockNone: !notificationStore.blockView }"></div>

    <div class="toolbar" v-if="routeName != 'Login' &&
      routeName != 'ResetPassword' &&
      routeName != 'RegistrationUserView'
    ">

      <ToolbarMenu></ToolbarMenu>

    </div>

    <div class="bgView ViewRouter">

      <router-view />

    </div>

  </w-app>
</template>

<style lang="scss">
@import url("./assets/css//normalize.css");
@import url("./assets/css/palette.css");
@import url("./assets/css/color.css");
@import url("./assets/css/icon.css");
@import url("./assets/css/bigTableComponent.css");
@import url("./assets/css/filterComponent.css");
@import url("./assets/css/generalTableComponent.css");
@import url("./assets/css/notification.css");
@import url("./assets/css/update.css");
@import url("./assets/css/updateBusinessClient.css");
@import url("./assets/css/comunication.css");
@import url("./assets/css/cssTable.css");

.ql-hidden {
  display: none;
}

.nNone {
  display: none;
}

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .inputPage {
    border: 0;
    width: 4rem;
    height: 2rem;
    font-size: 1rem;
  }

  .inputUpload {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding: 0.5rem;
    background-color: var(--tertiary);
    border: 0;
    border-radius: 10px;
    font-size: 1.1rem;
    color: white;
  }

  .vueApp {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toolbar {
    width: 13.5rem;
    height: 100%;
    z-index: 3;

    @media only screen and (max-width: 600px) {
      width: 3.75rem;
    }
  }

  .ViewRouter {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    width: 100%;
    height: 100vh;
    padding: 2% 4% 2% 4%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-left: 2px solid var(--tertiary);
  }



  @media only screen and (max-width: 800px) {
    .ViewRouter {
      padding: 0;
    }


  }

  @media only screen and (min-width: 800px) {}

}

#blockView {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.585);
  z-index: 4;
}

.blockNone {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.popUp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  background-color: green;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: fadeIn 3s;
}
</style>


<script>
import ToolbarMenu from "@/components/Menu/ToolbarMenu.vue";
import RegistrationUserView from './views/RegistrationUserView.vue';
import { computed } from '@vue/reactivity';
import LoginView from './views/LoginView.vue';
import { onMounted } from 'vue';
import { useDataStore } from './stores/DataStore';
import RegisterView from './views/RegisterView.vue';
import ApiService from './stores/ApiService';


export default {
  components: {
    ToolbarMenu,
    LoginView,
    RegisterView
  },
};

</script>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useNotificationStore } from './stores/Notification';
const notificationStore = useNotificationStore();
const routeName = computed(() => {
  return useRoute().name;
});

onMounted(async () => {
  // console.log(localStorage.getItem('jwt'));

  const router = useRouter()
  await router.isReady();
  const urlSplit = window.location.href.split('/');
  const route = urlSplit[urlSplit.length - 1];
  // console.log(route);
  if (route.split('?')[0] == 'ResetPassword') {
    return;
  }
  else if (localStorage.jwt && localStorage.jwt !== "" && localStorage.user != null) {
    // console.log(localStorage.user);
    try {


      const response = await ApiService.post("Authorize/VerifyToken", { token: localStorage.jwt });
      console.log(response);
      if (!response.data) {
        localStorage.jwt = "";
        router.push("/Login");
        return;
      }
    } catch (e) {
      localStorage.jwt = "";
      router.push("/Login");
      return;
    }

    useDataStore().setJwt(localStorage.jwt);
    useDataStore().setUser(JSON.parse(localStorage.getItem('user')));
    if (localStorage.user.userType == 0) {

      router.push("/RequestsViewAdmin")
    } else {
      router.push("/RequestsViewUser")

    }
    //router.push("/");
  } else {
    router.push("/Login");

  }
});


</script>


