const listCompanyType = 
    [
        {
          companyTypeId: 0,
          companyTypeName: "P.I forfettaria"
        },
        {
            companyTypeId: 1,
            companyTypeName: "Ditta individuale"
          },
          {
            companyTypeId: 2,
            companyTypeName: "S.R.L"
          },
          {
            companyTypeId: 3,
            companyTypeName: "S.R.L.S."
          },
          {
            companyTypeId: 4,
            companyTypeName: "SPA"
          },
          {
            companyTypeId: 5,
            companyTypeName: "SAPA"
          },
          {
            companyTypeId: 6,
            companyTypeName: "SS"
          },
          {
            companyTypeId: 7,
            companyTypeName: "SNC"
          },
          {
            companyTypeId: 8,
            companyTypeName: "SAS"
          },

      ];

export {listCompanyType}  