import { defineStore } from "pinia";
import {apiClient} from "../ApiService";
import { listRequestStatus } from "../../assets/list/requestStatus";
import { useNotificationStore } from "../Notification";
import { useRouter } from "vue-router";
export const useRequestStore = defineStore("request", {
  state: () => ({
    /* ANCHOR ST.POPUP*/
    popUp: false,
    popUpMessage: "",
    popUpClass: "",

    /* ANCHOR ST.R. DOCUMENT*/
    requestDocumentObj: {},
    requestDocumentNotification: {
      upload: false,
      send: false,
      riOpen: false
    },
    /* ANCHOR ST.RICHIESTE*/
    requestsParameter: {
      PageNumber: 1,
      RequestStatusEnum: "undefined",
      RequestTypeId: "undefined",
      UserType: "undefined"
    },
    fetchingRequests: false,
    requests: [],
    requestsMaxPage: 0,
    requestAdminObj: {},

    elements: [],
    pageSize: 9,
    fetching: false,
    fetchingType: false,
    attualPage: 1,
    filterStatus: "all",
    filterType: "all",
    requestType: [],
    order: "alphabetic",
    requestStatus: listRequestStatus,
    documents: [],
    documentsAr: {},
    fetchingDocumentAr: false,
    documentsArNotification: { create: false, deleteAr: false, delete: false },
    archive: true,
    documentSearch: "",
    documentPageArchive: 1,
    documentPageRequest: 1,
    requestNotCount: 0

  }),
  getters: {
    getRequestType: (state) => {
      return state.requestType;
    },
    getRequests: (state) => {
      return state.requests;
    },
    getRequest: (state) => {
      return (id) => {
        let op = state.requests.find(x => x.id == id);
        return op
      }
    },
    getRequestsMaxPage: (state) => {
      return state.requests;
    },
    getRequestsAttualPage: (state) => {
      return state.requestsParameter.PageNumber + 1;
    },
    getUnreadRequest: (state) => {
      return state.requestNotCount
    },
    getDocumentArchiveSearch: (state) => {
      let arrayFilteredWithSearch = [];
      arrayFilteredWithSearch = state.documentsAr.documents.filter((a) =>
        a.name.toLowerCase().includes(state.documentSearch.toLowerCase())
      );
      return arrayFilteredWithSearch;
    },
    getDocumentRequestSearch: (state) => {
      let arrayFilteredWithSearch = [];
      arrayFilteredWithSearch = state.documentsAr.requestDocuments.filter((a) =>
        a.name.toLowerCase().includes(state.documentSearch.toLowerCase())
      );
      return arrayFilteredWithSearch;
    },
    maxDocumentArchiveSearch: (state) => {
      return Math.ceil(state.getDocumentArchiveSearch.length / 10)
    },
    maxDocumentRequestSearch: (state) => {
      return Math.ceil(state.getDocumentRequestSearch.length / 10)
    },
    getDocumentArchivePage: (state) => {
      const index = (state.documentPageArchive - 1) * 10;
      return state.getDocumentArchiveSearch.slice(index, 10 + index)
    },
    getDocumentRequestPage: (state) => {
      const index = (state.documentPageRequest - 1) * 10;
      return state.getDocumentRequestSearch.slice(index, 10 + index)
    },
    
  },
  actions: {
    /*ANCHOR AC. POPUP*/
    blockPopUp() {
      this.popUp = false;
      this.popUpClass = "";
    },
    popUpSucces() {
      this.popUpClass = "popUpSucces";
      this.popUpMessage = "Operazione effettuata con successo";
      this.popUp = true;
      setTimeout(() => {
        this.blockPopUp()
      }, 2000);
    },
    popUpFail() {
      this.popUpClass = "popUpFail";
      this.popUpMessage = "Qualcosa è andato storto";
      this.popUp = true;
      setTimeout(() => {
        this.blockPopUp()
      }, 2000);
    },
    async setRequestType() {
      this.fetchingType = true;
      try {
        await apiClient.get("RequestType/GetRequestTypes").then((response) => {
          this.requestType = response.data;
        }).then(() => {
          this.fetchingType = false;

        });
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async setRequestStatus(id, status) {
      const request = this.elements.find((e) => e.id == id);
      request.requestStatus = status;
      try {
        await apiClient.put("Request/Update", request)
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },

    setFilterStatus(newFilter) {
      this.filterStatus = newFilter;
    },
    setFilterType(newFilter) {
      this.filterType = newFilter;
    },
    setOrder(newOrder) {
      this.order = newOrder;
    },
    setAttualPage(page) {
      this.attualPage = page;
    },

    fetchRequests(x) {
      if (x) {
        this.requestsParameter.PageNumber = 1;
      }
      this.fetchingRequests = true;

      let url = "?PageNumber=" + (this.requestsParameter.PageNumber - 1);
      if (this.requestsParameter.RequestStatusEnum != "undefined") {
        url += "&RequestStatusEnum=" + this.requestsParameter.RequestStatusEnum
      }
      if (this.requestsParameter.RequestTypeId != "undefined") {
        url += "&RequestTypeId=" + this.requestsParameter.RequestTypeId
      }
      if (this.requestsParameter.UserType != "undefined") {
        url += "&UserType=" + this.requestsParameter.UserType
      }
      apiClient.get("Request/GetRequests" + url).then((response) => {
        this.requests = response.data;
        //  console.log("lenght response data:", response.data.length)
      }).then(() => {
        apiClient.get("Request/Count" + url).then((response) => {
          this.requestsMaxPage = Math.ceil(response.data / 10)

          this.fetchingRequests = false;

        })
      }).then(() => {
        this.requestNotRead()
      }).catch(err => {
        // Handle error
        console.log(err);
        this.fetchingRequests = false;
      });
    },

    create(obj) {
      // POST request using fetch with set headers

      if (obj.startDate == "" || obj.endDate == "" || obj.requestTypeId == "") {
        alert("parametri incompleti");
        return;
      }
      apiClient
        .post("Request/Create", {
          description: obj.description,
          requestTypeId: obj.requestTypeId,
        })
        .then((response) => {
          let request ={
            id: response.data,
            requestStatus: 4,
            read: true,
          }

          apiClient
            .put("Request/Update/", request)
            .then(() => {
              this.fetchRequests();
              this.requestNotRead();
            });
        })
       
    },
    createDocument(obj) {
      // POST request using fetch with set headers
     
      if (obj.base64 == "" || obj.name == "" || obj.requestId == "") {
        alert("parametri incompleti");
        return;
      }
      apiClient
        .post("RequestDocument/Create", {
          base64: obj.base64,
          name: obj.name,
          requestId: obj.requestId,
          uploadType: obj.uploadType
        })
        .then(() => {
          this.fetchItems();
     
        });
      
      
    },
    createDocumentArray(documentArray) {
      let x = documentArray.length
      for (let i = 1; i <= x; i++) {
        apiClient.post("RequestDocument/Create", documentArray[i - 1]).then(() => {

          if (i == x) {
            this.fetchItems();
            

          }

        }).then(() => {
          if (i == x) {
            this.popUpSucces();
            this.requestDocumentNotification.upload = false;
            const not = useNotificationStore()
            not.allFalse();


          }

        }).then(() => {


        })
          .catch(err => {
            // Handle error
            console.log(err);
          });
      }

    },
    requestAllDocument(){
      this.fetching = true;
      apiClient.get("RequestDocument/AllDocuments").then((response) => {
        this.documents = response.data;

      }).then(() => {
        this.fetchRequests();
        this.fetching = false;
      });
    },
    deleteDocument(id) {
      apiClient
        .delete("RequestDocument/Delete", {
          data: {
            documentId: id,
          },
        })
        .then(() => {
          this.fetchRequests();
         
        });
    },
    update() {
      // POST request using fetch with set headers

      
      apiClient
        .put("Request/Update/", this.requestAdminObj)
        .then(() => {
          this.fetchItems();
          this.requestNotRead();
        });
     
    },
    updateUser(company) {
      apiClient.put("User/Update", company).then(() => {




        this.popUpSucces();
      }).catch(err => {
        // Handle error
        console.log(err);
      });
    },

    fetchAllDocument() {
      this.fetchingDocumentAr = true;
      apiClient.get("RequestDocument/AllDocuments").then((response) => {
        this.documentsAr = response.data
        this.fetchingDocumentAr = false
      }).then(() => {
        this.fetchingDocumentAr = false
      });
    },
    downloadDocument(id, fileName) {
      apiClient.get(
        "RequestDocument/Download/", {
        params: {
          documentId: id,
        }
      }
      )
        .then((response) => {
          const a = document.createElement("a"); //Create <a>
          a.href = "data:application/" + fileName.split('.')[1] + ";base64," + response.data; //Image Base64 Goes here
          a.download = fileName; //File name Here
          a.click();
         
        })
        .catch((e) => console.log(e));
    },
    downloadDocumentAr(id, fileName) {
      apiClient.get(
        "Document/Download/", {
        params: {
          documentId: id,
        }
      }
      )
        .then((response) => {
          const a = document.createElement("a"); //Create <a>
          a.href = "data:application/" + fileName.split('.')[1] + ";base64," + response.data; //Image Base64 Goes here
          a.download = fileName; //File name Here
          a.click();
          
        }).then(() => {
          this.fetchAllDocument()
        })
        .catch((e) => console.log(e));
    },
    move(id) {
      apiClient
        .post("RequestDocument/Move", {
          "requestDocumentId": id
        })
        .then(() => {
          this.fetchAllDocument()

        });
    },
    renameDocument(document) {
      apiClient
        .put("RequestDocument/Update", {
          "documentId": document.id,
          "name": document.value,
        })
        .then(() => {
          this.fetchAllDocument()

        });
    },
    renameDocumentArchive(document) {
      apiClient
        .put("Document/Update", {
          "documentId": document.id,
          "name": document.value,
        })
        .then(() => {
          this.fetchAllDocument()

        });
    },
    readRequest() {
      apiClient.put("Request/Update", this.requestAdminObj).then(() => {

      }).then(() => {
        this.requestNotRead();
      })
        .catch(err => {
          // Handle error
          console.log(err);
        });
    },
    requestNotRead() {
      apiClient.get("Request/CountNotRead").then((response) => {
        this.requestNotCount = response.data;


      }).catch(err => {
        // Handle error
        if (err.message == 'Request failed with status code 401') {
          localStorage.jwt = "";
          useRouter().push("/Login");
        }

      });
    },

    // populate(){
    //     businessFake.forEach(element => {
    //         this.create(element)
    //     });

    // },
    // cancel(){
    //     this.getElements.forEach(element => {

    //         this.delete(element.id+'')
    //     });
    // }
  },
});
