<template>

<router-link v-if="user.userType == 0" to="/RequestsViewAdmin" class="button-menu textToolbar"
  :class="$route.meta.group == 'richieste'? 'buttonOnToolbar' : 'buttonOff'">
  <img :src="[require(`../../assets/icon/clipboard-question-solid.svg`)]" class="icon iconColorToolbar" />
  <span>
    Richieste
        <span v-if="request > 0"
          style="background-color:white; color:red; width: 1.3rem; height: 1.3rem; vertical-align: center; display: inline-block; text-align: center; border-radius: 50%; line-height: 1.3rem;">
          {{
          request}}</span>
  </span>
</router-link>
<router-link v-else to="/RequestsViewUser" class="button-menu textToolbar"
  :class="$route.meta.group == 'richieste'? 'buttonOnToolbar' : 'buttonOff'">
  <img :src="[require(`../../assets/icon/clipboard-question-solid.svg`)]" class="icon iconColorToolbar" />
  <span>
    Richieste
    <span v-if="requestUser > 0"
      style="background-color:white; color:red; width: 1.3rem; height: 1.3rem; vertical-align: center; display: inline-block; text-align: center; border-radius: 50%; line-height: 1.3rem;">
      {{
      requestUser}}</span>
  </span>
</router-link>



<router-link v-if="user.userType == 0" to="/ComunicationAdmin" class="button-menu textToolbar"
  :class="$route.meta.group == 'comunicazioni'? 'buttonOnToolbar' : 'buttonOff'">
  <img :src="[require(`../../assets/icon/envelope-solid.svg`)]" class="icon iconColorToolbar" />
  <span>
    Comunicazioni 
  </span>
</router-link>
<router-link v-else to="/CommunicationUser" class="button-menu textToolbar"
  :class="$route.meta.group == 'comunicazioni'? 'buttonOnToolbar' : 'buttonOff'">
  <img :src="[require(`../../assets/icon/envelope-solid.svg`)]" class="icon iconColorToolbar" />
  <span>Comunicazioni 
    <span  v-if="comStore.getUnreadCommunications.length > 0"
    style="background-color:white; color:red; width: 1.3rem; height: 1.3rem; vertical-align: center; display: inline-block; text-align: center; border-radius: 50%; line-height: 1.3rem;">{{ comStore.getUnreadCommunications.length }}</span>
  </span>
</router-link>



  <router-link
    v-for="(item, index) in props.menu"
    :key="index"
    :to="item.link"
    class="button-menu textToolbar"
    :class="$route.meta.group == item.group? 'buttonOnToolbar' : 'buttonOff'"
  >
    <img :src="[require(`../../assets/icon/${item.icon}`)]" class="icon iconColorToolbar" />
    <span>
      {{item.name}} </span>
  
  </router-link>
</template>
<script>



export default {
  name: "MenuButtonDesktop",

};
</script>

<script setup>
import { defineProps, computed, reactive } from 'vue';
import { useDataStore } from '@/stores/DataStore';
//import {useRoute} from 'vue-router';
import { useUserCommunicationStore } from '@/stores/user/UserCommunicationStore';
import { useGeneralStore } from '@/stores/admin/AdminStore';
import { useRequestStore } from '@/stores/user/RequestStore';


const comStore = useUserCommunicationStore();
const store = useGeneralStore();
const storeData = useDataStore();

const userRequestStore = useRequestStore()
let user = reactive(storeData.getUser);

comStore.fetchUnreadCommunications();


const props = defineProps(['menu'])

store.requestNotRead()
userRequestStore.requestNotRead()
const request = computed(() => {
 return store.getUnreadRequest
})

const requestUser = computed(() => {
  return userRequestStore.getUnreadRequest
})



</script>

<style scoped lang="scss">
.toolbarName {
  font-size: 1.4rem;
  max-width: 60%;
}



.buttonOff:hover {
  backdrop-filter: brightness(110%) contrast(60%);
}
.icon {
  height: 1.2rem;
  margin-right: 0.8rem;
}

.button-menu {
  width: 100%;
  padding: 1rem 0.5rem 1rem 0.5rem;
  display: flex;
  align-items: center;
}
</style>