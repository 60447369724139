import router from "@/router/index.js";
import axios from "axios";
import { defineStore } from "pinia";


export const useDataStore = defineStore("data", {
  state: () => ({
    userData: {
      userImage:
        "https://cdn.shopify.com/s/files/1/0525/5200/7875/articles/barba-uomo-qual-e-la-tendenza-per-il-2021-475886.jpg?v=1623169395",
      user: "Antonio Rossi",
      position: "Garbagnate Milanese, Via XX Settembre 1",
    },
    jwt: "",
    user: {},
    textLogin: false,
    textResetPassword: false,
    notificactionReset: false,
    resetFetching: false,
    reset: 0,
  }),
  getters: {
    getUser: (state) => state.user,
  },
  actions: {
    setJwt(newJwt) {
      this.jwt = newJwt;
    },
    setUser(newUser) {
      this.user = newUser;
    },

    async login(code, pw) {
      try {
        let response = await axios.post(
          "https://api-fiscality-prod-001.azurewebsites.net/api/Authorize/Login",
          {
            fiscalCode: code,
            password: pw,
          }
        );

        this.jwt = response.data.token;
        this.user = response.data.user;

        localStorage.setItem("jwt", this.jwt);

        localStorage.setItem("user", JSON.stringify(this.user));

       // console.log(response.data);
        if (this.user.userType == 1) {
          this.textLogin = false;
          router.push("/RequestsViewUser");
          
        } 
        else if (this.user.userType == 2) {
          this.textLogin = false;
          router.push("/RequestsViewUser");
          
        }
        else if (this.user.userType == 0){
          this.textLogin = false;
          router.push("/RequestsViewAdmin");
          
        }
      } catch (error) {
      //  alert("Username o password errati");
        this.textLogin = true;
       console.log(error.message);
      }
    },
    sentResetPassword(fiscalCode) {
      // POST request using fetch with set headers

      if (fiscalCode == "") {
        alert("parametri incompleti");
        return;
      }
      this.textResetPassword = false;
      this.resetFetching = true;
      axios
        .post(
          "https://api-fiscality-prod-001.azurewebsites.net/api/User/SentResetPassword",
          {
            fiscalCode: fiscalCode,
          }
        )
        .then(() => {
          
          this.resetFetching = false;
          
         this.notificactionReset = true;
         // console.log(response);
        }).catch(()=>{
          this.textResetPassword = true;
          this.resetFetching = false;
        });
    },
    ResetPassword(code, newPassword) {
      // POST request using fetch with set headers

      if (code == "" || newPassword == "") {
        alert("parametri incompleti");
        return;
      }
      axios
        .post(
          "https://api-fiscality-prod-001.azurewebsites.net/api/User/ResetPassword",
          {
            code: code,
            password: newPassword,
          }
        )
        .then((response) => console.log(response.json()));
    },
  },
});
