<template>
  <div class="conteinerRegistrationUser">
 
    <div class="registrazioneImage">
      <img src="../assets/img/logo.png" alt="" class="logoRegistration" />
    </div>
       <div class="registrazione">
        <div style="display: flex; width: 100%">
          <router-link to="/Login" style="width: fit-content; height: fit-content; display: inline-block" v-if="!registrationForm.value">
            <img src="../assets/icon/arrow-left-solid.svg" alt="" style="width: 2rem; height: 2rem" />
          </router-link>
          <img src="../assets/icon/arrow-left-solid.svg" alt="" style="width: 2rem; height: 2rem" v-else
          @click="{ registrationForm.value = false; val.company = false; val.client = false}"/>
        </div>

        <div class="regModule" v-if="!registrationForm.value">
          <p>Scegli come registrarti</p>
          <button class="rInactive" style="background-color: var(--principalRed);"
            @click="{registrationType.value = 'privato'; registrationForm.value = true}">
            Privato
          </button>
          <button class="rInactive" style="background-color: var(--principalBlue);"
            @click="{ registrationType.value = 'azienda'; registrationForm.value = true }">
            Azienda
          </button>
        </div>
   
        <div class="regModule" v-if="registrationType.value == 'azienda' && registrationForm.value">
          
          <p v-if="val.company == true && company.companyName.length < 1" class="formAlert">Completa il nome</p>
          <input type="text" placeholder="Nome azienda*" v-model="company.companyName" class=" " />

          <p v-if="val.company == true && company.vatNumber.length < 1" class="formAlert">Completa il codice fiscale</p>
          <input type="text" placeholder="Partita Iva*" v-model="company.vatNumber" class=" " />
          <p v-if="val.company == true && ValidateEmail(company.email) == false" class="formAlert">Email non corretta</p>
          <input type="text" placeholder="Email*" v-model="company.email" class=" " />
          <p v-if="val.company == true && company.companyType == undefined" class="formAlert">Scegli una tipologia di azienda
          </p>
          <select name="" @click="hide" v-model="company.companyType" class=" ">
            <option value="undefined" id="placeholder" selected>
              Tipologia di azienda*
            </option>
            <option v-for="(element, index) in listCompanyType" :key="index" :value="element.companyTypeId">
              {{ element.companyTypeName }}
            </option>
          </select>
          <p v-if="val.company == true && company.state.length < 1" class="formAlert">Inserisci stato</p>
          <input type="text" v-model="company.state" />

        
          <div style="display: flex; align-items: center; width: 100%">
            <p v-if="val.company == true && company.city.length < 1" class="formAlert">Inserisci città</p>
            <p v-if="val.company == true && company.zipCode.length != 5" class="formAlert">Inserisci cap</p>
          </div>
          <div style="display: flex; align-items: center; width: 100%">
            <input type="text" v-model="company.city" placeholder="Città*" style="margin-right: 3px" />
         
            <input type="text" v-model="company.zipCode" placeholder="CAP*" style="margin-left: 3px; width: 40%" />
          </div>
          <div style="display: flex; align-items: center; width: 100%">
            <p v-if="val.company == true && company.street.length < 1" class="formAlert">Completa la via</p>
            <p v-if="val.company == true && company.streetNumber.length < 1" class="formAlert">Completa il civico</p>
          </div>
          <div style="display: flex; align-items: center; width: 100%">         
            <input type="text" v-model="company.street" placeholder="Via*" />
            <input type="text" v-model="company.streetNumber" placeholder="N*" style="margin-left: 5px; width: 5rem" />
          </div>
          <p v-if="val.company == true &&  regez.test(company.password) == false" class="formAlert">la password deve avere tra gli 8 e i 15 caratteri, almeno una lettera maiuscola,minuscola, un numero e un carattere speciale</p>
            <p v-else class="formAlert" style="color: black;">la password deve avere tra gli 8 e i 15 caratteri, almeno una lettera maiuscola,minuscola, un numero e un carattere speciale</p>
          <input type="password" v-model="company.password" placeholder="Password*" />
        </div>
        <div class="regModule" v-if="registrationType.value == 'privato' && registrationForm.value">
          <p v-if="val.client == true && client.name.length < 1" class="formAlert">Completa il nome</p>
          <input type="text" placeholder="Nome*" v-model="client.name" class=" " />
          <p v-if="val.client == true && client.surname.length < 1" class="formAlert">Completa il cognome</p>
          <input type="text" placeholder="Cognome*" v-model="client.surname" class=" " />
          <p v-if="val.client == true && client.fiscalCode.length < 1" class="formAlert">Completa il codice fiscale</p>
          <input type="text" placeholder="Codice Fiscale*" v-model="client.fiscalCode" class=" " />
          <p v-if="val.client == true && client.email.length < 1" class="formAlert">Completa l'email</p>
          <input type="text" placeholder="Email*" v-model="client.email" class=" " />
          <p v-if="val.client == true && client.state.length < 1" class="formAlert">Completa lo stato</p>
          <input type="text" v-model="client.state" />
          <div style="display: flex; align-items: center; width: 100%">
            <p v-if="val.client == true && client.city.length < 1" class="formAlert">Inserisci città</p>
            <p v-if="val.client == true && client.zipCode.length != 5" class="formAlert">Inserisci cap</p>
          </div>
          <div style="display: flex; align-items: center; width: 100%">
            <input type="text" v-model="client.city" placeholder="Città*" style="margin-right: 3px" />
            <input type="text" v-model="client.zipCode" placeholder="CAP*" style="margin-left: 3px; width: 40%" />
          </div>
          <div style="display: flex; align-items: center; width: 100%">
            <p v-if="val.client == true && client.street.length < 1" class="formAlert">Completa la via</p>
            <p v-if="val.client == true && client.streetNumber.length < 1" class="formAlert">Completa il civico</p>
          </div>
          <div style="display: flex; align-items: center; width: 100%">
            <input type="text" v-model="client.street" placeholder="Via*" />
            <input type="text" v-model="client.streetNumber" placeholder="N*" style="margin-left: 5px; width: 6rem" />
          </div>
          <p v-if="val.client == true &&  regez.test(client.password) == false" class="formAlert">la password deve avere tra gli 8 e i 15 caratteri, almeno una lettera maiuscola,minuscola, un numero e un carattere speciale</p>
          <p v-else class="formAlert" style="color: black;">la password deve avere tra gli 8 e i 15 caratteri, almeno una lettera maiuscola, minuscola, un numero e un carattere speciale</p>          
          <input type="password" v-model="client.password" placeholder="Password*" />
        </div>

        <p v-if="(val.company == true || val.client == true ) && documentSite.privacy == false " class="formAlert" style="text-align: center;">Devi accettare la privacy policy
        </p>
        <div class="checkboxPrivacy" v-if="registrationForm.value">
          <input type="checkbox" id="privacyCheck" v-model="documentSite.privacy" />
          <span>Ho scaricato, letto e accettato la
            <a href="/Privacy-Policy-sul-Trattamento-dei-Dati.pdf" download>
              privacy policy*
            </a>
          </span>
        </div>
        <p v-if="(val.company == true || val.client == true) && documentSite.use == false" class="formAlert" style="text-align: center;">Devi accettare le condizioni d'uso
        </p>
        <div class="checkboxPrivacy" v-if="registrationForm.value">
          <input type="checkbox" id="conditionCheck" v-model="documentSite.use" />
          <span>Ho scaricato, letto e accettato le
            <a href="/Condizioni-d-uso.pdf" download>
              condizioni d'uso del sito*
            </a>
          </span>
        </div>
        <div class="sendReg" v-if="registrationForm.value">
          <button class="rActive" v-if="registrationType.value == 'privato'" @click="createClient">
            Invia
          </button>
          <button class="rActive" v-if="registrationType.value == 'azienda'" @click="createCompany">
            Invia
          </button>
        </div>
      
      </div>
  </div>
</template>

<script>


export default {
  name: "RegistrationUserView"
};

function ValidateEmail(input) {

  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {

    // alert("Valid email address!");


    return true;

  } else {

    return false;

  }

}
</script>

<script setup>
import { reactive } from 'vue';
import { listCompanyType } from '@/assets/list/companyType';
import ApiService from '@/stores/ApiService';
import router from '@/router';

const regez = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
const registrationForm = reactive({value: false})
const registrationType = reactive({ value: "privato" });
const val = reactive({ company: false, client: false });
const documentSite = reactive({
  privacy: false,
  use: false
})
document.privacy
const company = reactive({
  companyName: "",
  vatNumber: "",
  email: "",
  companyType: undefined,
  state: "Italia",
  city: "",
  zipCode: "",
  street: "",
  streetNumber: "",
  password: "",
  userType: 2,
});

const client = reactive({
  email: "",
  name: "",
  surname: "",
  fiscalCode: "",
  password: "",
  state: "Italia",
  city: "",
  zipCode: "",
  street: "",
  streetNumber: "",
  userType: 1,
})

function createClient() {
  if (
    ValidateEmail(client.email) == false ||
    client.name.length < 1 ||
    client.surname.length < 1 ||
    client.fiscalCode.length < 1 ||
    client.password.length < 8 ||
    client.state.length < 1 ||
    client.city.length < 1 ||
    client.zipCode.length != 5 ||
    client.street.length < 3 ||
    client.streetNumber.length < 1 ||
    regez.test(client.password) == false,
    documentSite.value == false ||
    documentSite.privacy == false
  ) {
    val.client = true;
    console.log("ferma");
    console.log(company)
    return
  }
  else {
    ApiService.post("Authorize/Registration", client).then(() => {
      router.push("/Login")
    }).catch(err => {
      // Handle error
      console.log(err);
    });
  }


}

function createCompany() {
  val.company = false;
  if (
    ValidateEmail(company.email) == false ||
    company.companyName.length < 1 ||
    company.vatNumber.length < 1 ||
    company.state.length < 1 ||
    company.city.length < 1 ||
    company.zipCode != 5 ||
    company.street.length < 1 ||
    company.streetNumber.length < 1 ||
    company.companyType == undefined ||
    regez.test(company.password) == false,
    documentSite.value == false ||
    documentSite.privacy == false
  ) {
    val.company = true;
    console.log("ferma");
    console.log(company)
    return
  }
  else {
    ApiService.post("Authorize/Registration", company).then(() => {
      router.push("/Login")
    }).catch(err => {
      // Handle error
      console.log(err);
    });
  }


}
</script>

<style>
.conteinerRegistrationUser {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;

  justify-content: center;
}

.registrazione {
  width: 50%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.registrazioneImage {
  background-image: url("../assets/img/fiscalyBackGround.png");
    width: 50%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

}

@media only screen and (max-width: 800px) {
  .conteinerRegistrationUser {
    flex-wrap: wrap-reverse;
    text-align: center;
  }

  .registrazione {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .registrazioneImage {
    width: 100%;
    display: none;
  }

  .checkboxPrivacy {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column-reverse;
  margin: 0.2rem;
}
}

.logoRegistration {
  width: 95%;
}

.regType {
  display: flex;
  align-items: center;
}

.regType button {
  border: 0;
  padding: 0.7rem;
  margin: 10px 5px 5px 5px;
  font-size: 1.2rem;
}

.sendReg button {
  border: 0;
  padding: 0.7rem;
  margin: 5px 5px 10px 5px;
  font-size: 1.2rem;
  border-radius: 10px;
}

.rActive {
  background-color: var(--principalRed) !important;
  color: white !important;
  border-radius: 10px;
}

.rInactive {
  background-color: gainsboro;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 0;
  padding: 0.5rem;
  margin: 1rem;
  width: 5rem;
  text-align: center;
  font-size: 1.1rem;
}

.regModule {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px #999;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.regModule input {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}

.regModule select {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}

@media only screen and (min-width: 800px) {
  .regModule {
    max-width: 400px;
  }
  .checkboxPrivacy {
  max-width: 400px;
  }
}

.linkPrivacy {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.linkPrivacy img {
  height: 1.5rem;
  margin-right: 10px;
}

.linkPrivacy p {
  line-height: 1.5rem;
  vertical-align: middle;
  font-size: 1.3rem;
}

.checkboxPrivacy {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 0.2rem;
}

.checkboxPrivacy input {
  width: fit-content;
  margin-right: 10px;
}

.checkboxPrivacy span {
  width: fit-content;
}

.formAlert {
  color: red;
  width: 100%;
  padding: 0.1rem 0.1rem 0.1rem 0.5rem;
}
</style>
