const listRequestStatus =
  [
    {
      id: 0,
      admin: "Inviato",
      user: "Da completare"
    },
    {
      id: 1,
      admin: "Da confermare",
      user: "Inviato"
    },
    {
      id: 2,
      admin: "Completata",
      user: "Completata"
    },
    {
      id: 3,
      admin: "Richiesta riapertura",
      user: "Richiesta riapertura"
    },
    {
      id: 4,
      admin: "Da completare",
      user: "Inviata"
    },

  ];

export { listRequestStatus }  