<template>
  <div class="fill-height w-flex justify-start column" style="width: 100%">


    <router-link v-if="user.userType == 0" to="/RequestsViewAdmin" class="button-menu textToolbar"
      :class="$route.meta.group == 'richieste' ? 'buttonOnToolbar' : 'buttonOff'">
      <img :src="[require(`../../assets/icon/clipboard-question-solid.svg`)]" class="icon iconColorToolbar" />
      <span v-if="request > 0"
        style="background-color:white; color:red; width: 1.3rem; height: 1.3rem; vertical-align: center; display: inline-block; text-align: center; border-radius: 50%; line-height: 1.3rem;">
        {{
          request
        }}</span>
    </router-link>
    <router-link v-else to="/RequestsViewUser" class="button-menu textToolbar"
      :class="$route.meta.group == 'richieste' ? 'buttonOnToolbar' : 'buttonOff'">
      <img :src="[require(`../../assets/icon/clipboard-question-solid.svg`)]" class="icon iconColorToolbar" />
    </router-link>

    <router-link v-if="user.userType == 0" to="/ComunicationAdmin" class="button-menu textToolbar"
      :class="$route.meta.group == 'comunicazioni' ? 'buttonOnToolbar' : 'buttonOff'">
      <img :src="[require(`../../assets/icon/envelope-solid.svg`)]" class="icon iconColorToolbar" />
    </router-link>
    <router-link v-else to="/CommunicationUser" class="button-menu textToolbar"
      :class="$route.meta.group == 'comunicazioni' ? 'buttonOnToolbar' : 'buttonOff'">
      <img :src="[require(`../../assets/icon/envelope-solid.svg`)]" class="icon iconColorToolbar" />
      <span v-if="comStore.getUnreadCommunications.length > 0"
        style="background-color:white; color:red; width: 1rem; height: 1rem; vertical-align: center; display: inline-block; text-align: center; border-radius: 30%;">{{
          comStore.getUnreadCommunications.length
        }}</span>
    </router-link>




    <router-link v-for="(item, index) in props.menu" :key="index" :to="item.link" class="button-menu textToolbar"
      :class="$route.meta.group == item.group ? 'buttonOnToolbar' : 'buttonOff'">
      <img :src="[require(`../../assets/icon/${item.icon}`)]" class="icon iconColorToolbar" />
    </router-link>
  </div>
</template>
<script>




export default {
  name: "MenuButtonMobile",

};
</script>

<script setup>
import { defineProps, computed, reactive } from 'vue';
import { useUserCommunicationStore } from '@/stores/user/UserCommunicationStore';
import { useGeneralStore } from '@/stores/admin/AdminStore';
import { useDataStore } from '@/stores/DataStore';

const storeData = useDataStore();

let user = reactive(storeData.getUser);



const store = useGeneralStore();

store.requestNotRead()

const request = computed(() => {
  return store.getUnreadRequest
})

const comStore = useUserCommunicationStore();

comStore.fetchUnreadCommunications();


const props = defineProps(['menu'])


</script>

<style scoped lang="scss">
.buttonOff:hover {
  backdrop-filter: brightness(110%) contrast(60%);
}

.icon {
  height: 1.2rem;

}

.button-menu {
  width: 100%;
  padding: 1rem 0.5rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>