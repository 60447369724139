import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notification", {
  state: () => ({
   blockView: false,
   pannel: false,
  }),
  getters: {
   
  },
  actions: {
      blockViewTrue() {
        this.blockView = true;
      },
      blockViewFalse() {
        this.blockView = false;
      },
      pannelFalse() {
        this.pannel = false;
      },
      pannelTrue() {
        this.pannel = true;
      },
      allFalse() {
        this.pannel = false;
        this.blockView = false;
      },
      allTrue() {
        this.pannel = true;
        this.blockView = true;
      },
  },
});