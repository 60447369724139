<template>
 
  <w-toolbar vertical class="w-flex column fill-height align-start justify-start correction bgToolbar textToolbar"
    v-if="windowWidth > 600">
     <div class="tolBlock" :class="{ nNone: !logoutPannel }">

    </div>
    <div class="notification" id="notificationPannel"  
    :class="{ nNone: !logoutPannel }" style="position: fixed;"
     >
      <!--  -->

        <div class="containerNotification" >
            <div class="testaNotification">
                <img src="../../assets/icon/right-from-bracket-solid.svg" alt="" class="notificationIcon" />
                <p>Uscire</p>
            </div>

            <div class="bodyNotification">

                <p style="color: black;">sicuro di voler uscire?</p> 

                <div class="notificationButtonDiv">

                    <button @click="logout"><span>Esci</span></button><button
                        @click="logoutPannel = false"><span>Annulla</span></button>
                </div>
            </div>
        </div>
    </div>
    <div class="w-flex xs12 pa3 align-center justify-center">
      <w-flex class="align-center ma2">
        <p class=" toolbarName mr3">
          {{ dataStore.getUser.name ?? dataStore.getUser.companyName }}
        </p>

        <div @click="logoutPannel = true" class="align-self-end ">
          <w-icon style="cursor: pointer;" title="uscire">
            mdi mdi-logout
          </w-icon>
        </div>
      </w-flex>

    </div>

    <w-divider class="ma2 dividerToolbar"></w-divider>


    <MenuButtonDesktop :menu="menu" />

    <div class="fill-height"></div>

    <w-divider class="dividerToolbar"></w-divider>

    <p class="pa2">
      {{ dataStore.userData.position }}
    </p>

  </w-toolbar>

  <!-- mobile under 600 -->

  <w-toolbar vertical class="w-flex column fill-height align-center bgToolbar textToolbar justify-start correction"
    v-else>
   
    <div @click="logout" class="align-center">
          <w-icon>
            mdi mdi-logout
          </w-icon>
        </div>
    <w-divider class="ma2 dividerToolbar"></w-divider>

    <MenuButtonMobile :menu="menu" />
  </w-toolbar>
</template>

<script>

export default {
  name: "ToolbarMenu",
};


</script>
<script setup>
import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
import MenuButtonDesktop from './MenuButtonDesktop.vue';
import MenuButtonMobile from './MenuButtonMobile.vue';
import { useDataStore } from '@/stores/DataStore.js';
import { menuAdmin, menuUser } from '../../assets/list/MenuConstant.js'
import { useRouter } from 'vue-router';



const dataStore = useDataStore()


const windowWidth = ref(0);

const router = useRouter();

const menu = computed(() => {
  if (dataStore.getUser.userType == 0) {
    return menuAdmin;
  }
  return menuUser;
})

function resize() {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  nextTick(() => {
    window.addEventListener("resize", resize);
  });
  windowWidth.value = window.innerWidth;

});

onUnmounted(() => {
  window.removeEventListener("resize", resize);

});

const logoutPannel = ref(false)
function logout() {
  localStorage.jwt = "";
  console.log(router);
  router.push("/Login");

}

</script>


<style lang="scss" scoped>
.menu {
  padding-right: 2rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.correction {
  border: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.tolBlock {
  position: fixed;
  z-index: 4;
  background-color: rgba(128, 128, 128, 0.353);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
