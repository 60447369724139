import axios from "axios";
//import { useRouter } from 'vue-router';


const apiClient =  axios.create({
  // https://api-fiscality-qa-001.azurewebsites.net/api/",
  baseURL: "https://api-fiscality-prod-001.azurewebsites.net/api/",
  transformRequest: [
    function (data, headers) {
      headers["Authorization"] = "Bearer " + localStorage.getItem("jwt");
      return JSON.stringify(data);
    },
  ],
  headers: {
    "Content-Type": "application/json",
  },
});



export {apiClient}
