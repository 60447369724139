import {
    defineStore
} from "pinia";
import {apiClient} from "../ApiService";
import {
    useNotificationStore
} from "../Notification";
import { useRouter } from "vue-router";
export const useGeneralStore = defineStore("general", {
    state: () => ({
        /* ANCHOR ST.POPUP*/
        popUp: false,
        popUpMessage: "",
        popUpClass: "",
        /* ANCHOR ST.RICHIESTE*/
        requestsParameter: {
            PageNumber: 1,
            RequestStatusEnum: "undefined",
            RequestTypeId: "undefined",
            UserType: "undefined"
        },
        fetchingRequests: false,
        requests: [],
        requestNotCount: undefined,
        requestsMaxPage: 0,
        requestAdminObj: {},
        /* ANCHOR ST.R.TYPE*/
        fetchingRequestType: false,
        requestType: [],
        requestTypeObj: {},
        /* ANCHOR ST.R. DOCUMENT*/
        requestDocumentObj: {},
        requestDocumentNotification: {
            upload: false,
            reject: false,
        },
        /* ANCHOR ST.CLIENT*/
        clients: [],
        fetchingClients: false,
        clientId: undefined,
        clientAdminObj: {},
        clientParameter: {
            PageNumber: 1,
            order: "alphabetic",
            search: "",
            searchType: "name",
        },
        clientNotification: {
            create: false,
            delete: false,
            approve: false,
        },
        clientDelete: "",
        /* ANCHOR ST. COMPANY*/
        companies: [],
        fetchingCompanies: false,
        companyNotification: {
            create: false,
            delete: false,
            approve: false,
        },
        companyId: undefined,
        companyAdminObj: {},
        companyDelete: "",
        companyParameter: {
            PageNumber: 1,
            filter: "all",
            order: "alphabetic",
            UserType: "undefined",
            search: "",
            searchType: "name",
        },
        /*ANCHOR ST.COMMUNICATION */
        fetchingCommunication: false,
        communications: [],
        communicationObj: {},
        /*ANCHOR ST.CO.LIST */
        fetchingCommunicationList: false,
        communicationLists: [],
        communicationListObj: {},
        /*ANCHOR ST. DOCUMENT ARCHIVE*/
        documentsAr: {},
        documentSearch: "",
        fetchingDocumentAr: false,
        documentsArNotification: { create: false, deleteAr: false, delete: false },
        archive: true,


    }),
    getters: {
        /*ANCHOR GE.RICHIESTE*/
        getRequests: (state) => {
            return state.requests;
        },
        getRequest: (state) => {
            return (id) => {
                let op = state.requests.find(x => x.id == id);
                return op
            }
        },
        getRequestsMaxPage: (state) => {
            return state.requests;
        },
        getRequestsAttualPage: (state) => {
            return state.requestsParameter.PageNumber + 1;
        },
        getUnreadRequest: (state) => {
            return state.requestNotCount
        },
        /*ANCHOR GE.CLIENTS*/
        getClients: (state) => {
            return state.clients;
        },
        getClientsFilter: (state) => {
            let arrayFilteredWithSearch = [];
            if (state.clientParameter.searchType == "name") {
                arrayFilteredWithSearch = state.clients.filter((a) =>
                    (a.surname + " " + a.name + " " +a.surname ).toLowerCase().includes(state.clientParameter.search.toLowerCase())
                );
            } else if (state.clientParameter.searchType == "code") {
                arrayFilteredWithSearch = state.clients.filter((a) =>
                    a.fiscalCode.toLowerCase().includes(state.clientParameter.search.toLowerCase())
                );
            }
            let elements = [];

            if (state.clientParameter.order == "alphabetic") {
                elements = arrayFilteredWithSearch.sort((a, b) =>
                    (a.surname + a.name).localeCompare(b.surname + b.name)
                );
            } else if (state.clientParameter.order == "alphabetic-reverse") {
                elements = arrayFilteredWithSearch.sort(
                    (a, b) => -(a.surname + a.name).localeCompare(b.surname + b.name)
                );
            } else if (state.clientParameter.order == "creation") {
                elements = arrayFilteredWithSearch.sort(function (a, b) {
                    Date.parse(a.created) - Date.parse(b.created);
                });
            } else if (state.clientParameter.order == "creation-reverse") {
                let x = arrayFilteredWithSearch.sort(function (a, b) {
                    Date.parse(a.created) - Date.parse(b.created);
                });
                elements = x.reverse()
            }

            return elements;
        },
        getClientsPage: (state) => {
            const index = (state.clientParameter.PageNumber - 1) * 10;
            return state.getClientsFilter.slice(index, 10 + index)
        },
        getClientsMaxPages: (state) => {
            return Math.ceil(state.getClientsFilter.length / 10);
        },
        getClient: (state) => {
            return (id) => {
                let op = state.clients.find(x => x.id == id);
                return op
            }
        },
        /*ANCHOR GE.COMPANY*/
        getCompaniesFilter: (state) => {
            let arrayFilteredWithSearch = [];
            if (state.companyParameter.searchType == "name") {
                arrayFilteredWithSearch = state.companies.filter((a) =>
                    a.companyName.toLowerCase().includes(state.companyParameter.search.toLowerCase())
                );
            } else if (state.companyParameter.searchType == "code") {
                arrayFilteredWithSearch = state.companies.filter((a) =>
                    a.vatNumber.toLowerCase().includes(state.companyParameter.search.toLowerCase())
                );
            }
            let elements = [];

            if (state.companyParameter.order == "alphabetic") {
                elements = arrayFilteredWithSearch.sort((a, b) =>
                    a.companyName.localeCompare(b.companyName)
                );
            } else if (state.companyParameter.order == "alphabetic-reverse") {
                elements = arrayFilteredWithSearch.sort(
                    (a, b) => -a.companyName.localeCompare(b.companyName)
                );
            } else if (state.companyParameter.order == "creation") {
                elements = arrayFilteredWithSearch.sort(function (a, b) {
                    Date.parse(a.created) - Date.parse(b.created);
                });
            } else if (state.companyParameter.order == "creation-reverse") {
                let x = arrayFilteredWithSearch.sort(function (a, b) {
                    Date.parse(a.created) - Date.parse(b.created);
                });
                elements = x.reverse()
            }



            let elements2 = []
            if (state.companyParameter.filter == "all") {
                elements2 = elements;
            } else {
                elements2 = elements.filter((a) =>
                    a.companyType == Number(state.companyParameter.filter)
                );
            }

            return elements2;

            // const index = state.companyParameter.PageNumber * 10;
            // return elements2.slice(index, 10 + index);

        },
        getCompaniesPage: (state) => {
            const index = (state.companyParameter.PageNumber - 1) * 10;
            return state.getCompaniesFilter.slice(index, 10 + index)
        },
        getCompaniesMaxPages: (state) => {
            return Math.ceil(state.getCompaniesFilter.length / 10);
        },
        getCompany: (state) => {
            return (id) => {
                let op = state.companies.find(x => x.id == id);
                return op
            }
        },
    },
    actions: {
        /*ANCHOR AC. POPUP*/
        blockPopUp() {
            this.popUp = false;
            this.popUpClass = "";
        },
        popUpSucces() {
            this.popUpClass = "popUpSucces";
            this.popUpMessage = "Operazione effettuata con successo";
            this.popUp = true;
            setTimeout(() => {
                this.blockPopUp()
            }, 2000);
        },
        popUpFail() {
            this.popUpClass = "popUpFail";
            this.popUpMessage = "Qualcosa è andato storto";
            this.popUp = true;
            setTimeout(() => {
                this.blockPopUp()
            }, 2000);
        },
        /*ANCHOR AC.RICHIESTE */
        /*fissa i parametri della chiamata richieste */
        setRequestsParameter(parameter) {
            this.requestsParameter = parameter
        },
        /* Chiama le richieste della pagina scelta */
        fetchRequests() {

            this.fetchingRequests = true;

            let url = "?PageNumber=" + (this.requestsParameter.PageNumber - 1);
            if (this.requestsParameter.RequestStatusEnum != "undefined") {
                url += "&RequestStatusEnum=" + this.requestsParameter.RequestStatusEnum
            }
            if (this.requestsParameter.RequestTypeId != "undefined") {
                url += "&RequestTypeId=" + this.requestsParameter.RequestTypeId
            }
            if (this.requestsParameter.UserType != "undefined") {
                url += "&UserType=" + this.requestsParameter.UserType
            }
            apiClient.get("Request/GetRequests" + url).then((response) => {
                this.requests = response.data;
                this.fetchingRequests = false;

            }).catch(err => {
                // Handle error
                console.log(err);
                this.fetchingRequests = false;
            });
        },
        requestNotRead() {
            apiClient.get("Request/CountNotRead").then((response) => {
                this.requestNotCount = response.data;


            }).catch(err => {
                // Handle error
                console.log(err);
                if (err.message == 'Request failed with status code 401' ){
                    localStorage.jwt = "";
                    useRouter().push("/Login");
                }

            });
        },
        /* Chiama le richieste della pagina scelta e il numero massimo delle pagine */
        fetchRequestsAll(x) {

            if (x) {
                this.requestsParameter.PageNumber = 1;
            }
            this.fetchingRequests = true;

            let url = "?PageNumber=" + (this.requestsParameter.PageNumber - 1);
            if (this.requestsParameter.RequestStatusEnum != "undefined") {
                url += "&RequestStatusEnum=" + this.requestsParameter.RequestStatusEnum
            }
            if (this.requestsParameter.RequestTypeId != "undefined") {
                url += "&RequestTypeId=" + this.requestsParameter.RequestTypeId
            }
            if (this.requestsParameter.UserType != "undefined") {
                url += "&UserType=" + this.requestsParameter.UserType
            }
            apiClient.get("Request/GetRequests" + url).then((response) => {
                this.requests = response.data;
                //  console.log("lenght response data:", response.data.length)
            }).then(() => {
                apiClient.get("Request/Count" + url).then((response) => {
                    this.requestsMaxPage = Math.ceil(response.data / 10)

                    this.fetchingRequests = false;

                })
            }).then(() => {
                this.requestNotRead()
            }).catch(err => {
                // Handle error
                console.log(err);
                this.fetchingRequests = false;
            });
        },
        /*setta l'oggetto della richiesta admin*/
        setRequestAdminObj(adminCreate) {
            this.requestAdminObj = adminCreate
        },
        /*crea la richiesta admin*/
        createRequestAdmin() {
            apiClient.post("Request/CreateByAdmin", this.requestAdminObj).then(() => {
                this.fetchRequestsAll();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        /*modifica richiesta admin*/
        updateRequest() {
            this.requestAdminObj.read = false;
            apiClient.put("Request/Update", this.requestAdminObj).then(() => {
                this.popUpSucces();
            }).then(() => {
                this.fetchRequestsAll();
                
            })
                .catch(err => {
                    // Handle error
                    console.log(err);
                });
        },
        readRequest() {
            apiClient.put("Request/Update", this.requestAdminObj).then(() => {

            }).then(() => {
                this.fetchRequestsAll();
                this.requestNotRead();
            })
                .catch(err => {
                    // Handle error
                    console.log(err);
                });
        },
        /*ANCHOR AC.R.TYPE */
        fetchRequestType() {
            if (this.requestType.length == 0) {
                this.fetchingRequestType = true;
            }
            apiClient.get("RequestType/GetRequestTypes").then((response) => {
                this.requestType = response.data;
                this.fetchingRequestType = false;
            }).catch(err => {
                // Handle error
                console.log(err);
                this.fetchingRequestType = false;
            });
        },
        setRequestTypeObj(adminCreate) {
            this.requestTypeObj = adminCreate;
        },
        createRequestType() {
            apiClient.post("RequestType/Create", this.requestTypeObj).then(() => {
                this.fetchRequestType();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        deleteRequestType() {
            apiClient.delete("RequestType/Delete", {
                data: this.requestTypeObj
            }).then(() => {
                this.fetchRequestType();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        updateRequestType() {
            apiClient.put("RequestType", this.requestTypeObj).then(() => {
                this.fetchRequestType();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        /*ANCHOR AC.R.DOCUMENTI */
        setRequestDocumentObj(adminCreate) {
            this.requestDocumentObj = adminCreate
        },
        createDocument(documentArray, idUser) {
            let x = documentArray.length
            for (let i = 1; i <= x; i++) {
                apiClient.post("RequestDocument/Create", documentArray[i - 1]).then(() => {

                    if (i == x) {
                        this.fetchRequestsAll();
                        if (idUser) {
                            this.fetchAllDocument(idUser);
                        }

                    }

                }).then(() => {
                    if (i == x) {
                        this.popUpSucces();
                        this.requestDocumentNotification.upload = false;
                        const not = useNotificationStore()
                        not.allFalse();


                    }

                }).then(() => {


                })
                    .catch(err => {
                        // Handle error
                        console.log(err);
                    });
            }

        },
        deleteDocument(idDelete, idUser) {
            apiClient.delete("RequestDocument/Delete", {
                data: {
                    documentId: idDelete,
                },
            }).then(() => {
                this.fetchRequests();

                if (idUser) {
                    this.fetchAllDocument(idUser);
                }
            }).then(() => {

                this.popUpSucces();

            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        downloadDocument(id, fileName) {
            apiClient.get(
                "RequestDocument/Download/", {
                params: {
                    documentId: id,
                }
            }

            )
                .then((response) => {
                    const a = document.createElement("a"); //Create <a>
                    a.href = "data:application/" + fileName.split('.')[1] + ";base64," + response.data; //Image Base64 Goes here
                    a.download = fileName; //File name Here
                    a.click();
                    console.log(response);
                })
                .catch((e) => console.log(e));
        },
        downloadZip(ids, utente) {
            let url = "RequestDocument/DownloadZip?";
            let url2 = "";
            ids.forEach(element => {
                if (element == ids[0]) {
                    url2 += ("DocumentIds=" + element)
                } else {
                    url2 += ("&DocumentIds=" + element)
                }
            });
            let urlApi = url + url2
            apiClient.get(
                urlApi
            )
                .then((response) => {
                    const a = document.createElement("a"); //Create <a>
                    a.href = "data:application/" + ";base64," + response.data; //Image Base64 Goes here
                    a.download = utente + ".zip"; //File name Here
                    a.click();
                    console.log(response.data);
                })
                .catch((e) => console.log(e));
        },
        /*ANCHOR AC.CLIENTS */
        fetchClients() {
            if (this.clients.length == 0) {
                this.fetchingClients = true;
            }
            apiClient.get("User/GetPersonals").then((response) => {
                this.clients = response.data;
                this.fetchingClients = false;
            }).catch(err => {
                // Handle error
                console.log(err);
                this.fetchingClients = false;
            });
        },
        /*setta l'oggetto CLIENTE admin*/
        setClientAdminObj(adminCreate) {
            this.clientAdminObj = adminCreate
        },
        createClient() {
            apiClient.post("User/Create", this.clientAdminObj).then(() => {
                this.fetchClients();
                this.popUpSucces();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        updateClient() {
            apiClient.put("User/Update", this.clientAdminObj).then(() => {

                this.fetchClients();
                this.popUpSucces()
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },

        deleteClient() {
            apiClient.delete("User/Delete", {
                data: {
                    id: this.clientDelete,
                },
            }).then(() => {
                this.fetchClients();
                this.popUpSucces();

            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        /*ANCHOR AC.COMPANIES */
        fetchCompanies() {
            if (this.companies.length == 0) {
                this.fetchingCompanies = true;
            }
            apiClient.get("User/GetCompanies").then((response) => {
                this.companies = response.data;
                this.fetchingCompanies = false;

            }).catch(err => {
                // Handle error
                console.log(err);
                this.fetchingCompanies = false;
            });
        },
        /*setta l'oggetto azienda admin*/
        setCompanyAdminObj(adminCreate) {
            this.clientAdminObj = adminCreate
        },
        createCompany() {
            apiClient.post("User/Create", this.companyAdminObj).then(() => {
                this.fetchCompanies();
                this.popUpSucces()
            }).catch(err => {
                // Handle error
                console.log(err);
                this.popUpFail()
            });
        },
        updateCompany(company) {
            apiClient.put("User/Update", company).then(() => {

                this.fetchCompanies();


                this.popUpSucces();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        deleteCompany() {
            apiClient.delete("User/Delete", {
                data: {
                    id: this.companyDelete,
                },
            }).then(() => {
                this.fetchCompanies();
                this.popUpSucces();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        /*ANCHOR AC.COMMUNICATION */
        fetchCommunications() {
            if (this.communications.length == 0) {
                this.fetchingCommunication = true;
            }
            apiClient.get("Communication/GetAllComunications").then((response) => {
                this.communications = response.data;
                this.fetchingCommunication = false;
            }).catch(err => {
                // Handle error
                console.log(err);
                this.fetchingCommunication = false;
            });
        },
        setCommunicationObj(adminCreate) {
            this.communicationObj = adminCreate;
        },
        createCommunication() {
            apiClient.post("Communication/Create", this.communicationObj).then(() => {
                this.fetchCommunications();
                this.setCommunicationObj({})
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        deleteCommunication() {
            apiClient.delete("Communication/Delete", {
                data: this.communicationObj
            }).then(() => {
                this.fetchCommunications();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        /*ANCHOR AC.CO.LIST*/
        fetchCommunicationLists() {
            if (this.communicationLists.length == 0) {
                this.fetchingCommunicationList = true;
            }
            apiClient.get("CommunicationList/GetComunicationLists").then((response) => {
                this.communicationLists = response.data;
                this.fetchingCommunicationList = false;
            }).catch(err => {
                // Handle error
                console.log(err);
                this.fetchingCommunicationList = false;
            });
        },
        setCommunicationListObj(adminCreate) {
            this.communicationListObj = adminCreate;
        },
        createCommunicationList() {
            apiClient.post("CommunicationList/Create", this.communicationListObj).then(() => {
                this.fetchCommunicationLists();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        deleteCommunicationList() {
            apiClient.delete("CommunicationList/Delete", {
                data: this.communicationListObj
            }).then(() => {
                this.fetchCommunicationLists();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        updateCommunicationList() {
            apiClient.put("CommunicationList", this.communicationListObj).then(() => {
                this.fetchCommunicationLists();
            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        /* ANCHOR AC. DOCUMENT ARCHIVE */
        fetchAllDocument(id) {
            this.fetchingDocumentAr = true;
            apiClient.get("RequestDocument/AllDocuments?UserId=" + id).then((response) => {
                this.documentsAr = response.data

            }).then(() => {
                this.fetchingDocumentAr = false
            });
        },
        createDocumentAr(documentArray, idUser) {
            let x = documentArray.length
            for (let i = 1; i <= x; i++) {
                apiClient.post("Document/Create", documentArray[i - 1]).then(() => {
                    if (i == x) {
                        this.fetchAllDocument(idUser);
                    }
                }).then(() => {
                    if (i == x) {
                        this.popUpSucces();
                        this.documentsArNotification.create = false;
                        useNotificationStore().allFalse();
                    }
                }).then(() => {
                }).catch(err => {
                    // Handle error
                    console.log(err);
                });
            }

        },
        deleteDocumentAr(idDelete, idUser) {
            apiClient.delete("Document/Delete", {
                data: {
                    documentId: idDelete,
                },
            }).then(() => {



                this.fetchAllDocument(idUser);

            }).then(() => {

                this.popUpSucces();

            }).catch(err => {
                // Handle error
                console.log(err);
            });
        },
        downloadDocumentAr(id, fileName) {
            apiClient.get(
                "Document/Download/", {
                params: {
                    documentId: id,
                }
            }
            )
                .then((response) => {
                    const a = document.createElement("a"); //Create <a>
                    a.href = "data:application/" + fileName.split('.')[1] + ";base64," + response.data; //Image Base64 Goes here
                    a.download = fileName; //File name Here
                    a.click();
                    console.log(response);
                })
                .catch((e) => console.log(e));
        },

    },
});