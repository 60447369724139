import { createRouter, createWebHistory } from "vue-router";
import { useDataStore } from "@/stores/DataStore";

//GENERALI

import LoginView from "../views/LoginView.vue";

const routes = [
  //GENERALI

  {
    path: "/Login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/Register",
    name: "Register",
    component: () => import('../views/RegisterView.vue'),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorView",
    component: () => import('../views/ErrorView.vue')
  },

  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import('../views/RegisterView.vue'),
  },
  {
    path: "/RegistrationUserView",
    name: "RegistrationUserView",
    component: () => import('../views/RegistrationUserView.vue'),
  },

  //ADMIN
  {
    path: "/ComunicationAdmin",
    name: "ComunicationAdmin",
    component: () => import('../views/admin/comunication/CommunicationAdmin.vue'),
    meta: { group: "comunicazioni" }
  },

  {
    path: "/GroupView",
    name: "GroupView",
    component: () => import('../views/admin/comunication/CommunicationGroup.vue'),
    meta: { group: "group" }
  },
  {
    path: "/GroupCreate",
    name: "GroupCreate",
    component: () => import('../views/admin/comunication/CommunicationGroupCreate.vue'),
    meta: { group: "group" },
  },
  {
    path: "/GroupUpdate/:id",
    name: "GroupUpdate",
    props: true,
    component: () => import('../views/admin/comunication/CommunicationGroupUpdate.vue'),
    meta: { group: "group" },
  },
  {
    path: "/SettingsAdmin",
    name: "SettingsAdmin",
    component: () => import('../views/admin/settings/SettingsAdmin.vue'),
    meta: { group: "impostazioni" },
  },
  {
    path: "/ClientsViewAdmin",
    name: "ClientsViewAdmin",
    component: () => import('../views/admin/client/ClientsViewAdmin.vue'),
    meta: { group: "privati" },
  },
  {
    path: "/ClientUpdateAdmin/:id",
    name: "ClientUpdateAdmin",
    props: true,
    component: () => import('../views/admin/client/ClientUpdateAdmin.vue'),
    meta: { group: "privati" },
  },
  {
    path: "/ClientsDocument/:id/:name",
    name: "ClientsDocument",
    props: true,
    component: () => import('../views/admin/client/ClientsDocument.vue'),
    meta: { group: "privati" },
  },
  {
    path: "/BusinessViewAdmin",
    name: "BusinessViewAdmin",
    component: () => import('../views/admin/business/BusinessViewAdmin.vue'),
    meta: { group: "aziende" },
  },
  {
    path: "/BusinessUpdateAdmin/:id",
    name: "BusinessUpdateAdmin",
    props: true,
    component: () => import('../views/admin/business/BusinessUpdateAdmin.vue'),
    meta: { group: "aziende" },
  },
  {
    path: "/BusinessDocument/:id/:name",
    name: "BusinessDocument",
    props: true,
    component: () => import('../views/admin/business/BusinessDocument.vue'),
    meta: { group: "aziende" },
  },
  {
    path: "/RequestTypeCreateAdmin",
    name: "RequestTypeCreateAdmin",
    component: () => import('../views/admin/requestType/RequestTypeCreateAdmin.vue'),
    meta: { group: "richieste" },
  },
  {
    path: "/RequestTypeUpdateAdmin/:id",
    name: "RequestTypeUpdateAdmin",
    props: true,
    component: () => import('../views/admin/requestType/RequestTypeUpdateAdmin.vue'),
    meta: { group: "richieste" },
  },
  {
    path: "/RequestTypeViewAdmin",
    name: "RequestTypeViewAdmin",
    props: true,
    component: () => import('../views/admin/requestType/RequestTypeViewAdmin.vue'),
    meta: { group: "richieste" },
  },
  {
    path: "/RequestsViewAdmin",
    name: "RequestsViewAdmin",
    component: () => import('../views/admin/request/RequestsViewAdmin.vue'),
    meta: { group: "richieste" },
  },
  {
    path: "/RequestUpdateAdmin/:id/:from",
    name: "RequestUpdateAdmin",
    props: true,
    component: () => import('../views/admin/request/RequestUpdateAdmin.vue'),
    meta: { group: "richieste" },
  },

  //USER

  {
    path: "/SettingsUser",
    name: "SettingsUser",
    component: () => import('../views/user/SettingsUser.vue'),
    meta: { group: "impostazioni" },
  },
  {
    path: "/RequestsViewUser",
    name: "RequestsViewUser",
    component: () => import('../views/user/request/RequestsViewUser.vue'),
    meta: { group: "richieste" },
  },
  {
    path: "/RequestUpdateUser/:id",
    name: "RequestUpdateUser",
    props: true,
    component: () => import('../views/user/request/RequestUpdateUser.vue'),
    meta: { group: "richieste" },
  },
  {
    path: "/CommunicationUser",
    name: "CommunicationUser",
    component: () => import('../views/user/CommunicationUser.vue'),
    meta: { group: "comunicazioni" },
  },
  {
    path: "/DocumentUser",
    name: "DocumentUser",
    component: () => import('../views/user/UserDocument.vue'),
    meta: { group: "documenti" },
  }, 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const publicPages = ["/Login", "/ResetPassword", "/RegistrationUserView"];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && useDataStore().jwt.length == 0) {
    return "/Login";
  }
});

export default router;