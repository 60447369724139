const menuAdmin = [
  {
    name: "Privati",
    icon: "user-tie-solid.svg",
    link: "/ClientsViewAdmin",
    group: "privati",
  },
  {
    name: "Aziende",
    icon: "industry-solid.svg",
    link: "/BusinessViewAdmin",
    group: "aziende",
  },
  {
    name: "Gruppi",
    icon: "people-group-solid.svg",
    link: "/GroupView",
    group: "group",
  },
  // {
  //   name: "Comunicazioni",
  //   icon: "envelope-solid.svg",
  //   link: "/ComunicationAdmin",
  //   group: "comunicazioni",
  // },
  // {
  //   name: "Richieste",
  //   icon: "clipboard-question-solid.svg",
  //   link: "/RequestsViewAdmin",
  //   group: "richieste",
   
  // },
  // {
  //   name: "Impostazioni",
  //   icon: "user-gear-solid.svg",
  //   link: "/SettingsAdmin",
  //   group: "impostazioni",
  // },
];
const menuUser = [
  // {
  //   name: "Richieste",
  //   icon: "clipboard-question-solid.svg",
  //   link: "/RequestsViewUser",
  //   group: "richieste",
  // },
  // {
  //   name: "Comunicazioni",
  //   icon: "envelope-solid.svg",
  //   link: "/CommunicationUser",
  //   group: "comunicazioni",
  // },
  {
    name: "Documenti",
    icon: "file-lines-regular.svg",
    link: "/DocumentUser",
    group: "documenti",
  },
  {
    name: "Profilo utente",
    icon: "user-gear-solid.svg",
    link: "/SettingsUser",
    group: "impostazioni",
  },
 
];

export { menuAdmin, menuUser };
