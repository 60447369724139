<template>
  <div class="blockLogin"  v-if="store.notificactionReset">

  </div>
  <div class="notification" id="notificationPannel" v-if="store.notificactionReset">

      <div class="containerNotification" >
          <div class="testaNotification">
            
              <p>Email inviata</p>
          </div>

          <div class="bodyNotification" style="padding: 3rem; box-sizing: border-box;">
            <p>La procedura si è svolta con successo. Ti abbiamo Inviato un'email, controlla la casella per procedere con il cambio password</p>
            <div class="notificationButtonDiv">
    
                  <button @click="{store.reset = 0;store.notificactionReset = false;}"><span>Torna alla pagina login</span></button>
                      
              </div>
            
          </div>
      </div>
  </div>
<div class="loginPage">
  <div class="lgLeft">
    <img src="../assets/img/logo.png" alt="" style="width: 95%; margin: auto;">
  </div>

  
  <div class="lgRight">
    
    <img src="../assets/img/logo.png" alt="" style="width: 80%; margin: 0px auto 3rem auto;" class="logoMobile">
    <form v-if="store.reset == 0" class="formReset">
      <h2 class="titleLogin">LOGIN</h2>

      <label for="fiscalcode">CODICE FISCALE/PARTITA IVA</label>
      <input type="text" id="fiscalCode" name="fiscalcode" v-model="fiscalCode" />

      <label for="password">PASSWORD</label>
      <div style="display: flex; justify-content: end; position: relative; width: 100%;">
         <span class="eyesIconSmall" @click="showPassword"></span>
      </div>
      <input type="password" id="passwordApp" name="password" v-model="password" autocomplete="current-password"/>
      <p v-if="store.textLogin" style="margin-bottom: 1rem; text-align: center; color: red;">Ops! Sembra che  la password e l'username non corrispondano. </p>
      <button @click="login" type="button" style="padding: 0.60rem; font-size: 1.1rem;">ACCEDI</button>

      <p style="color: blue; text-align: center; margin-top: 1rem; cursor: pointer;" @click="store.reset = 1">Password
        dimenticata?</p>
        <router-link to="/RegistrationUserView" style="color: blue; text-align: center; margin-top: 1rem; cursor: pointer;">
          Registrati al nostro servizio
        </router-link>
    </form>
  
     
      <form class="formReset" v-else-if="store.reset == 1">
        <label for="fiscalCodeReset">CODICE FISCALE\PARTITA IVA</label>
        <input type="text" id="fiscalCodeReset" name="fiscalCodeReset" v-model="fiscalCodeReset" />
        <p v-if="store.textResetPassword" style="margin-bottom: 1rem; text-align: center; color: red;">Ops! Codice Fiscale\Partita iva non corretto, riprova!</p>
        
             <span class="loaderIconSmall" v-if="store.resetFetching" style="margin: auto;"></span>
            <button @click="sentReset" type="button" v-else style="width: fit-content; padding: 0.6rem;font-size: 1rem; margin: auto;">INVIA</button>
        
      
      </form>
   
    <div v-if="store.reset == 1" style="position: absolute; top: 5%; left: 2%; height: 2rem; width: 2rem;" class="left-arrowIcon" @click="reset = 0">
    </div>
    
  </div>
</div>
</template>

<script>

export default {
name: "LoginView"
};
</script>
<script setup>

import { useDataStore } from '@/stores/DataStore.js';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
const store = useDataStore();

const fiscalCode = ref('');
const password = ref('');



const fiscalCodeReset = ref('');


onMounted(() => {
if (localStorage.jwt && localStorage.jwt !== "" && localStorage.user != null) {
 // console.log(localStorage.user);
  useDataStore().setJwt(localStorage.jwt);
  useDataStore().setUser(JSON.parse(localStorage.getItem('user')));
  useRouter().push("/");
}
})



function login() {
store.login(fiscalCode.value, password.value);

}

function sentReset() {
store.sentResetPassword(fiscalCodeReset.value)
//reset.value = 2;
}


function showPassword() {
  var x = document.getElementById("passwordApp");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}

</script>

<style scoped lang="scss">

 .formReset input{
  width: auto;
 }
.loginPage {
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}

.lgLeft {
width: 50%;
height: 100%;
background: url(../assets/img/fiscalyBackGround.png);
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 2rem;
color: white;
display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
align-items: center;
}

.blockLogin {
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.592);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.divider {
width: 20%;
height: 3px;
border-radius: 4rem;
background-color: white;
margin-top: 1rem;
margin-bottom: 1rem;
}

.lgRight {
height: 100%;
width: 50%;
padding: 2rem;
display: flex;
align-items: center;
justify-content: center;
position: relative;
}

form {
background-color: white;
display: flex;
flex-direction: column;
justify-content: center;
padding: 2rem;
width: 400px;
border-radius: 8px;
box-shadow: 0 0 4px 2px #999;

}

.titleLogin {
color: var(--tertiary);
text-align: center;
margin-bottom: 2rem;
}

input {
margin-bottom: 2rem;
margin-top: 0.2rem;
padding: 0.5rem;
border: 1px solid var(--tertiary);
border-radius: 8px;
}

label {
font-size: 0.8rem;
}

button {
padding: 0.4rem;
color: white;
border-radius: 10px;
background-color: var(--tertiary);
border: 0;
margin-left: 10%;
margin-right: 10%;
}

.mobileLogin {
display: none;
}

@media only screen and (min-width: 950px) { 
  .logoMobile {
    display: none;
  }

}

@media only screen and (max-width: 950px) {
.mobileLogin {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  color: rgb(255, 255, 255);

}

.lgLeft {
  display: none;
}

.lgRight {
  width: 100%;
  background: url(../assets/img/fiscalyBackGround.png);
  background-color: var(--leftScreenBackground);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form {
  width: 85%;
}
}
</style>
