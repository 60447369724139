import { defineStore } from "pinia";
import {apiClient} from "../ApiService";
export const useUserCommunicationStore = defineStore("communicationUser", {
  state: () => ({
    readCommunications: [],
    unreadCommunications: [],
    fetching: false,
  }),
  getters: {
    getReadCommunications: (state) => state.readCommunications,
    getUnreadCommunications: (state) => state.unreadCommunications,
    isFetching: (state) => state.fetching,
  },
  actions: {
    async fetchReadCommunications() {
      if (this.readCommunications.length == 0) {
        this.fetching = true;
      }
      try {
        await apiClient
          .get("Communication/GetComunicationsRead")
          .then((response) => {
            this.readCommunications = [];
            for (const element of response.data) {
              this.readCommunications.push(element);
            }
            
          });
      } catch (error) {
        alert(error);
        console.log(error);
      }
      this.fetching = false;
    },
    async fetchUnreadCommunications() {
      if (this.unreadCommunications.length == 0) {
        this.fetching = true;
      }
      try {
        await apiClient
          .get("Communication/GetComunicationsNotRead")
          .then((response) => {
            this.unreadCommunications = [];
            for (const element of response.data) {
              this.unreadCommunications.push(element);
            }
           
          });
      } catch (error) {
        alert(error);
        console.log(error);
      }
      this.fetching = false;
    },
    async fetchAllCommunications() {
      const store = useUserCommunicationStore();
      store.fetchReadCommunications();
      store.fetchUnreadCommunications();
    },
    downloadDocument(id, fileName) {
      apiClient.get(
        "Communication/Download/", {
        params: {
          DocumentId: id,
        }
      }

      )
        .then((response) => {
          const a = document.createElement("a"); //Create <a>
          a.href = "data:application/" + fileName.split('.')[1] + ";base64," + response.data; //Image Base64 Goes here
          a.download = fileName; //File name Here
          a.click();
          console.log(response);
        })
        .catch((e) => console.log(e));
    },

    async setCommunicationRead(id) {
     apiClient
          .put("Communication/Read", { comunicationId: id })
      .then(() => {
        this.fetchAllCommunications();

      });
      
    },
    archiveDocument(id) {
      apiClient.post(
        "Communication/Move/", {  
          communicationDocumentId: id,
        })
  },
}});
