<template>
<div class="loginPage">
  <div class="lgLeft">
    <h1>Reset password</h1>
    <div class="divider">

    </div>
    <h3>In caso di problemi tecnici</h3>
    <p>contattare team@voltar.it</p>
  </div>

  <div class="lgRight">
    <form>
      <h3 class="title" style="margin-bottom: 1rem;">Reset Password</h3>

      <label for="fiscalcode">Codice</label>
      <input type="text" id="fiscalCode" name="fiscalcode" v-model="code" />

      <label for="password">Nuova Password</label>
      <input type="password" v-model="password" />
      <label for="password">Conferma password</label>
      <input type="password" v-model="passwordConferma" />
      <div class="checkboxPrivacy">
        <input type="checkbox" id="privacyCheck"   style="margin: 0 0.5rem !important;" v-model="documentSite.privacy"/>
        <span>Ho scaricato, letto e accettato la
          <a href="/Privacy-Policy-sul-Trattamento-dei-Dati.pdf" download>
            privacy policy
          </a>
        </span>
      </div>
        <div class="checkboxPrivacy" style="margin-top: 1rem; margin-bottom: 1rem;">
          <input type="checkbox" id="conditionCheck" style="margin: 0 0.5rem !important;" v-model="documentSite.use"/>
          <span>Ho scaricato, letto e accettato le
            <a href="/Condizioni-d-uso.pdf" download>
              condizioni d'uso del sito
            </a>
          </span>
        </div>
      <button @click="register" type="button">Conferma</button>
    </form>
  </div>
</div>
</template>

<script>
export default {
name: "RegisterView"
};
</script>

<script setup>
import { ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import apiClient from "@/stores/ApiService";

const documentSite = reactive({
  privacy: false,
  use: false
})


const code = ref(useRoute().query.code);
const password = ref('');
const passwordConferma = ref('');

const router = useRouter();



function register() {
  if (documentSite.value == false ||
    documentSite.privacy == false){
      alert("Devi accettare le condizioni della privacy e le condizioni d'uso");
      return
    }
if (password.value != passwordConferma.value) {
  alert("Le password non coincidono");
  return
}
apiClient.post(
  'User/ResetPassword',

  {
    "password": password.value,
    "code": code.value,
  }

).then((response) => {
  alert('Cambio password avvenuto con successo');
  router.push("/Login");

//  console.log(response);

}).catch((error) => {
  alert('Si è verificato un errore, riprova più tardi');
  console.log(error);
});

}

// function login(event) {
//   store.login(fiscalCode.value, password.value);
// }

</script>

<style scoped lang="scss">
.loginPage {
width: 100%;
height: 100%;
border-radius: 20px;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}

.lgLeft {
width: 50%;
height: 100%;
background: url(../assets/img/fiscalyBackGround.png);
background-color: var(--leftScreenBackground);
background-position: center;
background-size: cover;
background-repeat: no-repeat;
padding: 2rem;
color: white;
display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
align-items: center;
}



.divider {
width: 20%;
height: 3px;
border-radius: 4rem;
background-color: white;
margin-top: 1rem;
margin-bottom: 1rem;
}

.lgRight {
height: 100%;
width: 50%;
padding: 2rem;
display: flex;
align-items: center;
justify-content: center;
}

form {
background-color: white;
display: flex;
flex-direction: column;
justify-content: center;
padding: 2rem;
width: 400px;
border-radius: 8px;
box-shadow: 0 0 4px 2px #999;
}

.titleLogin {
color: var(--tertiary);
text-align: center;
margin-bottom: 2rem;
}

input {
margin-bottom: 2rem;
margin-top: 0.2rem;
padding: 0.5rem;
border: 1px solid var(--tertiary);
border-radius: 8px;
}

label {
font-size: 0.8rem;
}

button {
padding: 0.4rem;
color: white;
border-radius: 10px;
background-color: var(--tertiary);
border: 0;
margin-left: 10%;
margin-right: 10%;
}

.mobileLogin {
display: none;
}

@media only screen and (max-width: 950px) {
.mobileLogin {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  color: rgb(255, 255, 255);

}

.lgLeft {
  display: none;
}

.lgRight {
  width: 100%;
  background: url(../assets/img/fiscalyBackGround.png);
  background-color: var(--leftScreenBackground);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form {
  width: 85%;
}
}
</style>

